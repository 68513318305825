import React, { ReactElement } from 'react';
import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  Row,
  Spin,
  Tabs,
  TabsProps,
  Typography,
  message,
} from 'antd';
import EditableText from 'client/component-library/EditableText/EditableText';
import { useTypedFlags } from 'client/component-library/FeatureFlags/hooks/useTypedFlags';
import { LEGACY_SITE } from 'client/modules/users/constants';
import FeatureUsage from 'client/modules/team/components/FeatureUsage';
import {
  getSectionPage,
  useGetAdminPermissionsForUser,
} from 'client/utils/permissions-validation/hooks/UseGetAdminPermissionsForUserQuery';
import { TeamRoutes, itemRender } from '../../RouteHelpers';
import TeamMembers from './TeamMembers';
import PackageSetting from './PackageSetting';
import { TeamSettings } from './TeamSettings';
import useTeamForm from '../../hooks/useTeamForm';
import { useTeamMembers } from '../../hooks/useTeamMembers';
import { useUpdateTeam } from '../../hooks/useUpdateTeam';
import FormerTeamMembers from './FormerTeamMembers';
import { TeamSearch } from '../TeamSearch';
import { SFDCInfo } from './SFDCInfo';
import PendingInvitations from './PendingInvitations';
import useTeamInvitations from '../../hooks/useTeamInvitations';
import useSelectedTab from '../../hooks/useSelectedTab';
import AddExistingUser from './AddExistingUsers';
import SSODetails from '../SSODetails';
import { teamPermissions } from '../../permissions/Team';

export const TeamPage = (): ReactElement => {
  const { initialValues, idTeam, refetchGetTeam, isLoading } = useTeamForm();
  const {
    columns,
    formerColumns,
    data,
    formerData,
    refetchGetTeamMembers,
    setTeamMemberFilter,
  } = useTeamMembers(initialValues.teamId);

  const handleRefetch = async () => {
    return Promise.all([refetchGetTeam(), refetchGetTeamMembers()]);
  };

  const { getFlag } = useTypedFlags();
  const isLegacySiteEnabled = getFlag(LEGACY_SITE);

  const TabKeys = {
    currentMembers: 'current-members',
    formerMembers: 'former-members',
    pendingInvitations: 'pending-invitations',
    featureUsage: 'feature-usage',
    sso: 'sso-details',
  };

  const { defaultActiveKey, onChangeTab } = useSelectedTab({
    tabItems: Object.values(TabKeys),
  });

  const { onSubmit } = useUpdateTeam(refetchGetTeam);

  const { data: adminPermissions } = useGetAdminPermissionsForUser();
  const section = getSectionPage(
    adminPermissions,
    teamPermissions.pageId,
    teamPermissions.sections[3].sectionId
  );

  const hasSSOPermission = section.permissionTypes.length > 0;

  const onSaveTeamName = (name: string) => {
    onSubmit(
      {
        team_name: name,
      },
      initialValues.teamId
    )
      .then(() => {
        message.success("Success! The team's name has been updated.");
        refetchGetTeam();
      })
      .catch(() => {
        message.error(
          "Error! Failed to update the team's name. Please try again later."
        );
      });
  };

  const { columns: pendingInvitationColumns, pendingInvitations } =
    useTeamInvitations({ idTeam });

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spin size="large" />
      </div>
    );
  }

  const teamNameValidation = (name: string) => {
    if (!name) {
      return 'Invalid input. Please enter a valid name.';
    }
    return '';
  };

  const tabItems: TabsProps['items'] = [
    {
      key: TabKeys.currentMembers,
      label: (
        <span className="text-xl">{`Team Members (${data?.length || 0})`}</span>
      ),
      children: (
        <TeamMembers
          columns={columns}
          data={data}
          idTeam={idTeam}
          onSetTeamMemberFilter={(value) => setTeamMemberFilter(value.trim())}
        />
      ),
    },
    {
      key: TabKeys.formerMembers,
      label: (
        <span className="text-xl">{`Former Members (${
          formerData?.length || 0
        })`}</span>
      ),
      children: <FormerTeamMembers columns={formerColumns} data={formerData} />,
    },
    {
      key: TabKeys.pendingInvitations,
      label: (
        <span className="text-xl">{`Invitations (${
          pendingInvitations?.length || 0
        })`}</span>
      ),
      children: (
        <PendingInvitations
          columns={pendingInvitationColumns}
          data={pendingInvitations}
        />
      ),
    },
    {
      key: TabKeys.featureUsage,
      label: <span className="text-xl">Feature Usage</span>,
      children: <FeatureUsage idTeam={idTeam} />,
    },
    {
      key: TabKeys.sso,
      label: <span className="text-xl">SSO Details</span>,
      children: <SSODetails idTeam={idTeam} />,
      disabled: !hasSSOPermission,
    },
  ];

  return (
    <>
      <Row>
        <Col xl={{ span: 12 }} md={{ span: 14 }}>
          <Row>
            <Col
              xl={{ push: 4 }}
              md={{ push: 3 }}
              xs={{ push: 1 }}
              className="mt-4"
            >
              <EditableText
                text={initialValues.name}
                onSave={onSaveTeamName}
                validation={teamNameValidation}
                ariaLabel="click to edit team name"
                render={(text) => (
                  <Typography.Title level={2}>{text}</Typography.Title>
                )}
              />
            </Col>
            <Col
              xl={{ push: 4 }}
              md={{ push: 3 }}
              xs={{ push: 1 }}
              className="flex items-center pl-4"
            >
              <Typography.Text>({initialValues.teamId})</Typography.Text>
            </Col>
          </Row>
          <Row>
            <Col xl={{ push: 4 }} md={{ push: 3 }} xs={{ push: 1 }}>
              <Breadcrumb
                routes={[
                  { breadcrumbName: 'Search', path: TeamRoutes.Search },
                  { breadcrumbName: `Team ${initialValues.teamId}`, path: '' },
                ]}
                itemRender={itemRender}
              />
            </Col>
          </Row>
        </Col>
        <Col xl={{ span: 12 }} md={{ span: 10 }}>
          <Row>
            <Col flex="auto" className="mt-4">
              <Row justify="end">
                <Col
                  xl={{ span: 12, pull: 4 }}
                  md={{ span: 20, pull: 5 }}
                  xs={{ span: 20, pull: 2 }}
                >
                  <TeamSearch />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row justify="end">
            <Col className="mt-4 items-end" xl={{ pull: 4 }} md={{ pull: 5 }}>
              <Row>
                <Col>
                  <AddExistingUser
                    idTeam={idTeam}
                    refetchTeamMembers={refetchGetTeamMembers}
                  />
                </Col>
                {isLegacySiteEnabled && (
                  <Col className="ml-2">
                    <Button
                      type="dashed"
                      target="_blank"
                      href={`${window.envVars.LEGACY_DOMAIN}/admin/team-edit/${idTeam}`}
                    >
                      Use Legacy Site
                    </Button>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <PackageSetting
          refetchTeam={handleRefetch}
          packageId={initialValues.packageID}
          packageName={initialValues.packageName}
          packageStart={initialValues.packageStart}
          packageEnd={initialValues.packageEnd}
          linkedEntity={parseInt(
            initialValues.linkedEntity as unknown as string,
            10
          )}
          idTeam={idTeam}
        />
        <TeamSettings
          domainWhitelist={initialValues.domainWhitelist}
          mfaStatus={initialValues.mfaStatus}
          lbsStatus={initialValues.lbsStatus}
          ssoStatus={initialValues.ssoStatus}
          refetchTeam={handleRefetch}
          teamId={initialValues.teamId}
          teamType={initialValues.teamType}
        />
        <SFDCInfo
          csRepEmail={initialValues.csRepEmail}
          recordedDate={initialValues.recordedDate}
          renewalDate={initialValues.renewalDate}
          sfAccountId={initialValues.sfAccountId}
          sfOwner={initialValues.sfOwner}
        />
      </Row>
      <Row>
        <Col push="2" span="20">
          <Divider className="border-t-2 border-black border-solid" />
        </Col>
      </Row>
      <Row align="bottom">
        <Col
          xs={{ span: 22, push: 1 }}
          md={{ push: 2, span: 20 }}
          className="pb-20"
        >
          <Tabs
            defaultActiveKey={defaultActiveKey}
            items={tabItems}
            onChange={onChangeTab}
          />
        </Col>
      </Row>
    </>
  );
};
