import React, { useEffect, useState } from 'react';
import {
  Button,
  Descriptions,
  Drawer,
  Input,
  List,
  Modal,
  Tag,
  Typography,
  message,
} from 'antd';
import { GeneralCbiEntityInfo } from '@cbinsights/cbientityservice/cbientityservice';
import { EditOutlined, LoadingOutlined } from '@ant-design/icons';
import { useMutation } from 'react-query';
import { LiveSearchType } from '@cbinsights/integratedsearchservice/integratedsearchservice';
import { useGetLiveSearch } from '../services/hooks/useGetLiveSearch';
import { updateTeamEntity } from '../services/api/UpdateTeamEntity';
import { updateUserEntity } from '../services/api/UpdateUserEntity';

const { Title } = Typography;

type EntityLinkingProps = {
  entity: GeneralCbiEntityInfo | null; // Allow null for no linked entity
  onEntityChange: () => void; // Callback for updating the linked entity
  isLoading: boolean;
  idTeam?: number;
  idUser?: number;
};

const EntityLinking: React.FC<EntityLinkingProps> = ({
  entity,
  onEntityChange,
  isLoading,
  idTeam,
  idUser,
}) => {
  const [isDrawerVisible, setDrawerVisible] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [entityList, setEntityList] = useState([]);

  const handleOpenDrawer = () => setDrawerVisible(true);
  const handleCloseDrawer = () => setDrawerVisible(false);

  const handleOpenModal = () => setModalVisible(true);
  const handleCloseModal = () => setModalVisible(false);

  const updateTeamEntityMutation = useMutation(updateTeamEntity);
  const updateUserEntityMutation = useMutation(updateUserEntity);

  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };

  const handleEntitySelect = (selectedEntity: { id: number; name: string }) => {
    if (idTeam) {
      updateTeamEntityMutation
        .mutateAsync({ id_entity: selectedEntity.id, id_team: idTeam })
        .then(() => {
          message.success('Entity linked successfully');
          onEntityChange();
          handleCloseModal();
        })
        .catch(() => {
          message.error('Failed to link entity. Please try again.');
        });
    }

    if (idUser) {
      updateUserEntityMutation
        .mutateAsync({ id_entity: selectedEntity.id, id_user: idUser })
        .then(() => {
          message.success('Entity linked successfully');
          onEntityChange();
          handleCloseModal();
        })
        .catch(() => {
          message.error('Failed to link entity. Please try again.');
        });
    }
  };

  const { data: liveSearch, isLoading: isLoadingLiveSearch } = useGetLiveSearch(
    {
      enabled: Boolean(searchTerm),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      params: { prefix: searchTerm, size: 10, typeFilters: [{ type: 1 }] },
    }
  );

  useEffect(() => {
    if (liveSearch?.suggestionGroups) {
      const entities = [];

      liveSearch.suggestionGroups.forEach((group) => {
        if (group.type === LiveSearchType.ORG) {
          group.suggestions.forEach((suggestion) => {
            entities.push({
              name: suggestion.org.name,
              id: suggestion.org.id_org,
            });
          });
        }
      });
      setEntityList(entities);
    }
  }, [liveSearch]);

  useEffect(() => {
    if (isModalVisible) {
      setSearchTerm('');
      setEntityList([]);
    }
  }, [isModalVisible]);

  const isLoadingAddEntity =
    updateTeamEntityMutation.isLoading ||
    updateUserEntityMutation.isLoading ||
    isLoadingLiveSearch;

  return (
    <>
      {entity?.idCbiEntity && !isLoading && (
        <Button type="link" className="mr-2" onClick={handleOpenDrawer}>
          {entity.entityNameInfo.primaryName || entity.idCbiEntity}
        </Button>
      )}

      <Button
        type="default"
        shape="circle"
        icon={<EditOutlined style={{ fontSize: '14px' }} />}
        onClick={handleOpenModal}
        loading={isLoading}
      />

      {entity && (
        <Drawer
          title={`Entity Details - ${
            entity?.entityNameInfo?.primaryName || 'Unknown'
          }`}
          placement="right"
          onClose={handleCloseDrawer}
          visible={isDrawerVisible}
          width={600}
        >
          <Title level={4}>General Information</Title>
          <Descriptions column={1} bordered>
            <Descriptions.Item label="Description">
              {entity.description}
            </Descriptions.Item>
            <Descriptions.Item label="Email">{entity.email}</Descriptions.Item>
            <Descriptions.Item label="Status">
              {entity.status?.status || 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Founded Year">
              {entity.foundedYear}
            </Descriptions.Item>
            <Descriptions.Item label="Total Funding">
              {entity.totalFunding.toLocaleString()}
            </Descriptions.Item>
            <Descriptions.Item label="VC Backed">
              <Tag color={entity.vcBacked ? 'green' : 'red'}>
                {entity.vcBacked ? 'Yes' : 'No'}
              </Tag>
            </Descriptions.Item>
          </Descriptions>

          <Title level={4} style={{ marginTop: '16px' }}>
            Related Entities
          </Title>
          <Descriptions column={1} bordered>
            <Descriptions.Item label="Parent Entities">
              {entity.parentEntities.map((parent) => (
                <Tag key={parent.idCbiEntity}>
                  {parent.entityNameInfo.primaryName}
                </Tag>
              ))}
            </Descriptions.Item>
            <Descriptions.Item label="Child Entities">
              {entity.childEntities.map((child) => (
                <Tag key={child.idCbiEntity}>
                  {child.entityNameInfo.primaryName}
                </Tag>
              ))}
            </Descriptions.Item>
          </Descriptions>
        </Drawer>
      )}

      <Modal
        title="Select Entity"
        visible={isModalVisible}
        onCancel={handleCloseModal}
        footer={null}
      >
        <Input.Search
          placeholder="Search for an entity"
          onSearch={handleSearch}
          enterButton
        />

        {isLoadingAddEntity && (
          <div className="flex justify-center mt-4">
            <LoadingOutlined size={10} />
          </div>
        )}

        {searchTerm && !isLoadingAddEntity && (
          <List
            className="mt-2"
            dataSource={entityList}
            renderItem={(item) => (
              <List.Item
                className="flex justify-between"
                onClick={() => handleEntitySelect(item)}
                style={{ cursor: 'pointer' }}
              >
                <span>{item.name}</span>
                <span>{item.id}</span>
              </List.Item>
            )}
          />
        )}
      </Modal>
    </>
  );
};

export default EntityLinking;
