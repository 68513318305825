// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.6.1
//   protoc               v5.29.3
// source: cbinsights/integratedsearchservice/integratedsearchservice.proto

/* eslint-disable */
import { type TimeZone } from "../../google/type/datetime";
import { type DayOfWeek } from "../../google/type/dayofweek";
import { type TimeOfDay } from "../../google/type/timeofday";
import { type Filter, type OrderBy } from "../api/api";

export const protobufPackage = "integratedsearchservice";

export enum LiveSearchType {
  UNKNOWN_LIVE_SEARCH_TYPE = "UNKNOWN_LIVE_SEARCH_TYPE",
  ORG = "ORG",
  TAG = "TAG",
  COLLECTION = "COLLECTION",
  YOUR_COLLECTION = "YOUR_COLLECTION",
  EXPERT_COLLECTION = "EXPERT_COLLECTION",
  ORG_HAS_TRANSCRIPTS = "ORG_HAS_TRANSCRIPTS",
  /** TOP_SEARCH_BAR - input only type. will not be present in responses. Can return cxn, tag, org, research, esp, or person */
  TOP_SEARCH_BAR = "TOP_SEARCH_BAR",
  PERSON = "PERSON",
  RESEARCH = "RESEARCH",
  /**
   * ESP - Deprecated
   *
   * @deprecated
   */
  ESP = "ESP",
  MARKET = "MARKET",
  /** KEYWORD - can add more like GEO, INDUSTRY, etc */
  KEYWORD = "KEYWORD",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum SelectMode {
  UNKNOWN_SELECT = "UNKNOWN_SELECT",
  ALL = "ALL",
  NONE = "NONE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum BooleanOp {
  UNKNOWN = "UNKNOWN",
  AND = "AND",
  OR = "OR",
  NOT = "NOT",
  IGNORE = "IGNORE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum BreadcrumbType {
  UNKNOWN_BREADCRUMB = "UNKNOWN_BREADCRUMB",
  SORT = "SORT",
  FILTER = "FILTER",
  SORT_AND_FILTER = "SORT_AND_FILTER",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum ComparisonType {
  UNKNOWN_COMPARISON_TYPE = "UNKNOWN_COMPARISON_TYPE",
  CONTAINS = "CONTAINS",
  EQUALS = "EQUALS",
  START_WITH = "START_WITH",
  GREATER_THAN = "GREATER_THAN",
  LOWER_THAN = "LOWER_THAN",
  BETWEEN = "BETWEEN",
  HAS = "HAS",
  BEFORE = "BEFORE",
  AFTER = "AFTER",
  EQUALS_DISPLAY_VALUE_ID = "EQUALS_DISPLAY_VALUE_ID",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum SortDirection {
  UNKNOWN_SORT_DIRECTION = "UNKNOWN_SORT_DIRECTION",
  ASC = "ASC",
  DESC = "DESC",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum SortType {
  SORT_TYPE_UNKNOWN = "SORT_TYPE_UNKNOWN",
  SORT_TYPE_ALPHABETICAL = "SORT_TYPE_ALPHABETICAL",
  SORT_TYPE_LAST_VIEWED = "SORT_TYPE_LAST_VIEWED",
  SORT_TYPE_LAST_EDITED = "SORT_TYPE_LAST_EDITED",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum SearchTab {
  UNKNOWN_TAB = "UNKNOWN_TAB",
  COMPANY_TAB = "COMPANY_TAB",
  INVESTOR_TAB = "INVESTOR_TAB",
  COMPANY_DEAL_TAB = "COMPANY_DEAL_TAB",
  RESEARCH_TAB = "RESEARCH_TAB",
  PATENT_TAB = "PATENT_TAB",
  EARNINGS_TAB = "EARNINGS_TAB",
  NEWS_TAB = "NEWS_TAB",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum StructureType {
  UNKNOWN_TYPE = "UNKNOWN_TYPE",
  LIST_TYPE = "LIST_TYPE",
  TREE_TYPE = "TREE_TYPE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum View {
  UNKNOWN_VIEW = "UNKNOWN_VIEW",
  LIST_VIEW = "LIST_VIEW",
  TABLE_VIEW = "TABLE_VIEW",
  DEAL_VIEW = "DEAL_VIEW",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum AddEntitiesToCxnWarning {
  NO_WARNING = "NO_WARNING",
  /** LIMIT_REACHED - Row limit reached */
  LIMIT_REACHED = "LIMIT_REACHED",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum DuplicatesCheck {
  UNKNOWN_CHECK = "UNKNOWN_CHECK",
  SKIP_CHECK_REMOVE_DUPES = "SKIP_CHECK_REMOVE_DUPES",
  PERFORM_CHECK = "PERFORM_CHECK",
  SKIP_CHECK_KEEP_DUPES = "SKIP_CHECK_KEEP_DUPES",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum ChartType {
  CHART_TYPE_UNSPECIFIED = "CHART_TYPE_UNSPECIFIED",
  PATENT_TRENDS = "PATENT_TRENDS",
  PATENT_TOP_PLAYERS = "PATENT_TOP_PLAYERS",
  PATENT_TOP_TOPICS = "PATENT_TOP_TOPICS",
  TOTAL_FUNDING_AND_DEALS = "TOTAL_FUNDING_AND_DEALS",
  TOP_COMPANIES_BY_FUNDING = "TOP_COMPANIES_BY_FUNDING",
  TOP_COMPANIES_BY_VALUATION = "TOP_COMPANIES_BY_VALUATION",
  TOP_COMPANIES_BY_HEADCOUNT = "TOP_COMPANIES_BY_HEADCOUNT",
  TOP_COMPANIES_BY_REVENUE = "TOP_COMPANIES_BY_REVENUE",
  REVENUE_MULTIPLE_BY_COMPANY = "REVENUE_MULTIPLE_BY_COMPANY",
  REVENUE_PER_EMPLOYEE = "REVENUE_PER_EMPLOYEE",
  VALUATION_PER_EMPLOYEE = "VALUATION_PER_EMPLOYEE",
  TOTAL_FUNDING_PER_EMPLOYEE = "TOTAL_FUNDING_PER_EMPLOYEE",
  TOP_INVESTORS_BY_DEAL = "TOP_INVESTORS_BY_DEAL",
  TOP_ACQUIRERS_BY_DEAL = "TOP_ACQUIRERS_BY_DEAL",
  INDUSTRY_ANALYTICS = "INDUSTRY_ANALYTICS",
  GEOGRAPHY_ANALYTICS = "GEOGRAPHY_ANALYTICS",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum DataFeedFrequency {
  DATA_FEED_FREQUENCY_UNSPECIFIED = "DATA_FEED_FREQUENCY_UNSPECIFIED",
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
  NEVER = "NEVER",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum EmailFrequency {
  EMAIL_FREQUENCY_UNSPECIFIED = "EMAIL_FREQUENCY_UNSPECIFIED",
  EMAIL_FREQUENCY_NEVER = "EMAIL_FREQUENCY_NEVER",
  EMAIL_FREQUENCY_DAILY = "EMAIL_FREQUENCY_DAILY",
  EMAIL_FREQUENCY_WEEKDAY = "EMAIL_FREQUENCY_WEEKDAY",
  EMAIL_FREQUENCY_WEEKLY = "EMAIL_FREQUENCY_WEEKLY",
  EMAIL_FREQUENCY_MONTHLY = "EMAIL_FREQUENCY_MONTHLY",
  EMAIL_FREQUENCY_REALTIME = "EMAIL_FREQUENCY_REALTIME",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum Field {
  FIELD_UNSPECIFIED = "FIELD_UNSPECIFIED",
  ID_DATA_FEED = "ID_DATA_FEED",
  ID_SAVED_SEARCH = "ID_SAVED_SEARCH",
  FREQUENCY = "FREQUENCY",
  TS_LAST_RUN = "TS_LAST_RUN",
  LAST_S3_FILENAME = "LAST_S3_FILENAME",
  ID_FREQUENCY = "ID_FREQUENCY",
  ID_USER = "ID_USER",
  ID_OUT_OF_THE_BOX_FEED = "ID_OUT_OF_THE_BOX_FEED",
  ID_TEAM = "ID_TEAM",
  HAS_RUN_BEFORE = "HAS_RUN_BEFORE",
  SEND_FULL_FEED = "SEND_FULL_FEED",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export interface ListParentsAndSubsidiariesRequest {
  id_orgs: number[];
}

export interface ListParentsAndSubsidiariesResponse {
  parents: { [key: number]: ListParentsAndSubsidiariesResponse_Orgs };
  subsidiaries: { [key: number]: ListParentsAndSubsidiariesResponse_Orgs };
}

export interface ListParentsAndSubsidiariesResponse_Orgs {
  orgs: Org[];
}

export interface ListParentsAndSubsidiariesResponse_ParentsEntry {
  key: number;
  value: ListParentsAndSubsidiariesResponse_Orgs | undefined;
}

export interface ListParentsAndSubsidiariesResponse_SubsidiariesEntry {
  key: number;
  value: ListParentsAndSubsidiariesResponse_Orgs | undefined;
}

export interface LiveSearchRequest {
  prefix: string;
  typeFilters: TypeFilter[];
  size: number;
}

export interface RecognizeNamedEntityRequest {
  searchTerm: string;
}

export interface GetSuggestedTermsRequest {
  searchTerm: string;
  tab: SearchTab;
}

export interface LogSearchWithZeroHitsRequest {
  multiSearchId: string;
  tab: SearchTab;
  browser: string;
  device: string;
  url: string;
  referrerUrl: string;
}

export interface Success {
  success: boolean;
}

export interface TypeFilter {
  type: LiveSearchType;
}

export interface RecognizeNamedEntityResponse {
  /** only support one named entity as of today but may support multiple in the future */
  namedEntities: LiveSearchSuggestion[];
}

export interface GetSuggestedTermsResponse {
  /** only support one named entity as of today but may support multiple in the future */
  suggestedTerms: LiveSearchSuggestion[];
}

export interface LiveSearchResponse {
  /** Search suggestions are grouped based on the typeFilters sent */
  suggestionGroups: GroupedSuggestion[];
  totalSuggestions: number;
}

export interface GroupedSuggestion {
  type: LiveSearchType;
  suggestions: LiveSearchSuggestion[];
}

/**
 * FE should be able to check which type
 * is being used as per explanation here
 * https://developers.google.com/protocol-buffers/docs/reference/javascript-generated#oneof
 */
export interface LiveSearchSuggestion {
  org?: LiveSearchOrg | undefined;
  tag?: LiveSearchTag | undefined;
  collection?: LiveSearchCollection | undefined;
  person?: LiveSearchPerson | undefined;
  research?:
    | LiveSearchResearch
    | undefined;
  /** 6 reserved for LiveSearchEsp type which was deprecated for LiveSearchMarket */
  market?: LiveSearchMarket | undefined;
  keyword?: LiveSearchKeyword | undefined;
}

export interface LiveSearchOrg {
  id_org: number;
  id_company: number;
  id_investor: number;
  name: string;
  url: string;
  alias: string;
  has_headcount: boolean;
  has_valuation: boolean;
  additional_url: string;
  has_stock: boolean;
  has_mosaic: boolean;
}

export interface LiveSearchTag {
  id_tag: string;
  name: string;
  is_expert: boolean;
  id_team: number;
  id_user: number[];
}

export interface LiveSearchCollection {
  id_collection: number;
  name: string;
  is_expert: boolean;
  id_team: number;
  id_user: number[];
}

export interface LiveSearchPerson {
  id_person: number;
  name: string;
  /** for example Founder & CEO, not a historic list */
  id_title: number[];
  title: string[];
  id_org: number;
  org_name: string;
}

export interface LiveSearchResearch {
  id_research: number;
  name: string;
  link: string;
  publish_date: string;
  content_tier_new_id: number;
  is_expert_research: boolean;
}

export interface LiveSearchMarket {
  id_market: number;
  name: string;
  market_industry: string;
  market_sub_industries: string[];
}

export interface LiveSearchKeyword {
  keyword: string;
  confidence: number;
}

export interface SearchRequest {
  query: Expression | undefined;
  pagination:
    | PaginationRequest
    | undefined;
  /** (etsai) Avoid using the filter expression for now */
  filter: Expression | undefined;
  columns: ColumnRequest[];
  sorts: SortRequest[];
}

export interface EditSearchRequestWrapper {
  editQuery: EditQuery | undefined;
  editPagination:
    | EditPagination
    | undefined;
  /** (etsai) Avoid using the filter expression for now */
  editFilter: EditFilter | undefined;
  editColumn: EditColumn | undefined;
  editSort: EditSort | undefined;
  editPanelCategory: EditPanelCategory | undefined;
  editChart: EditChart | undefined;
}

export interface GetSearchRequestWrapper {
  pagination: PaginationRequest | undefined;
  columns: ColumnRequest[];
  sorts: SortRequest[];
}

export interface EditQuery {
  isChanged: boolean;
  query: Expression | undefined;
}

export interface EditPagination {
  isChanged: boolean;
  pagination: PaginationRequest | undefined;
}

export interface EditFilter {
  isChanged: boolean;
  filter: Expression | undefined;
}

export interface EditedChart {
  title: string;
  footnote: string;
  type: ChartType;
  startDate: string;
  endDate: string;
  /** Generic View state for any chart. example for TOTAL_FUNDING_AND_DEALS: 0 - Funding and Deals, 1 - Funding Only, 2 - Deals Only */
  view: number;
  orgEdit: EditedChart_OrgEdit[];
}

export interface EditedChart_OrgEdit {
  idOrgs: number[];
}

export interface EditChart {
  isChanged: boolean;
  charts: EditedChart[];
}

export interface EditColumn {
  isChanged: boolean;
  columns: ColumnRequest[];
}

export interface EditSort {
  isChanged: boolean;
  sorts: SortRequest[];
}

export interface PaginationRequest {
  limit: number;
  offset: number;
}

export interface EditPanelCategory {
  isChanged: boolean;
  operator: BooleanOp;
  panelColumn: PanelColumn | undefined;
  idPanelCategory: number;
}

export interface NewSearchRequest {
  company: SearchRequest | undefined;
  investor: SearchRequest | undefined;
  companyDeal: SearchRequest | undefined;
  news: SearchRequest | undefined;
  query: Expression | undefined;
  research: SearchRequest | undefined;
  isAdvanced: boolean;
  earnings: SearchRequest | undefined;
  patents: SearchRequest | undefined;
  includeTableKPIs: boolean;
  generateBreadcrumbs: boolean;
  isSemanticSearch: boolean;
}

export interface EditSearchRequest {
  searchId: string;
  company: EditSearchRequestWrapper | undefined;
  investor: EditSearchRequestWrapper | undefined;
  companyDeal: EditSearchRequestWrapper | undefined;
  news: EditSearchRequestWrapper | undefined;
  editQuery: EditQuery | undefined;
  research: EditSearchRequestWrapper | undefined;
  editUniverseQuery: EditPanelCategory | undefined;
  earnings: EditSearchRequestWrapper | undefined;
  patents: EditSearchRequestWrapper | undefined;
}

export interface EditSearchRequestByIndex {
  index: number;
  company: EditSearchRequestWrapper | undefined;
  investor: EditSearchRequestWrapper | undefined;
  companyDeal: EditSearchRequestWrapper | undefined;
  news: EditSearchRequestWrapper | undefined;
  editQuery: EditQuery | undefined;
  research: EditSearchRequestWrapper | undefined;
  editUniverseQuery: EditPanelCategory | undefined;
  earnings: EditSearchRequestWrapper | undefined;
  includeTableKPIs: boolean;
  patents: EditSearchRequestWrapper | undefined;
  isCustom: boolean;
}

export interface GetSearchRequest {
  searchId: string;
  company: GetSearchRequestWrapper | undefined;
  investor: GetSearchRequestWrapper | undefined;
  companyDeal: GetSearchRequestWrapper | undefined;
  news: GetSearchRequestWrapper | undefined;
  getTags: boolean;
  getCollections: boolean;
  research: GetSearchRequestWrapper | undefined;
  earnings: GetSearchRequestWrapper | undefined;
  patents: GetSearchRequestWrapper | undefined;
  includeTableKPIs: boolean;
  disableHighlights: boolean;
}

export interface GetSearchRequestByIndex {
  index: number;
  company: GetSearchRequestWrapper | undefined;
  investor: GetSearchRequestWrapper | undefined;
  companyDeal: GetSearchRequestWrapper | undefined;
  news: GetSearchRequestWrapper | undefined;
  research: GetSearchRequestWrapper | undefined;
  earnings: GetSearchRequestWrapper | undefined;
  patents: GetSearchRequestWrapper | undefined;
  includeTableKPIs: boolean;
}

export interface EditSearchFilterGroupRequest {
  searchId: string;
  company: FilterGroupRequest | undefined;
  investor: FilterGroupRequest | undefined;
  companyDeal: FilterGroupRequest | undefined;
  news: FilterGroupRequest | undefined;
  research: FilterGroupRequest | undefined;
  earnings: FilterGroupRequest | undefined;
  allowMissingColumn: boolean;
  patents: FilterGroupRequest | undefined;
  skipSearch: boolean;
}

export interface EditSearchFilterGroupRequestByIndex {
  index: number;
  company: FilterGroupRequest | undefined;
  investor: FilterGroupRequest | undefined;
  companyDeal: FilterGroupRequest | undefined;
  news: FilterGroupRequest | undefined;
  research: FilterGroupRequest | undefined;
  earnings: FilterGroupRequest | undefined;
  patents: FilterGroupRequest | undefined;
  includeTableKPIs: boolean;
  allowMissingColumn: boolean;
}

export interface FilterGroupRequest {
  selectMode: SelectMode;
  key: number;
  /** All operands should match the key specified */
  operands: OperandInfo[];
}

export interface OperandInfo {
  operand: FilterToken | undefined;
  operandRange: RangeFilterToken | undefined;
}

export interface RemoveSearchFilterGroupRequest {
  searchId: string;
  company: RemoveFilterGroupRequest | undefined;
  investor: RemoveFilterGroupRequest | undefined;
  companyDeal: RemoveFilterGroupRequest | undefined;
  earnings: RemoveFilterGroupRequest | undefined;
  research: RemoveFilterGroupRequest | undefined;
  news: RemoveFilterGroupRequest | undefined;
  patents: RemoveFilterGroupRequest | undefined;
}

export interface RemoveSearchFilterGroupRequestByIndex {
  index: number;
  company: RemoveFilterGroupRequest | undefined;
  investor: RemoveFilterGroupRequest | undefined;
  companyDeal: RemoveFilterGroupRequest | undefined;
  earnings: RemoveFilterGroupRequest | undefined;
  research: RemoveFilterGroupRequest | undefined;
  news: RemoveFilterGroupRequest | undefined;
  patents: RemoveFilterGroupRequest | undefined;
  includeTableKPIs: boolean;
}

export interface RemoveFilterGroupRequest {
  key: number;
}

export interface NewSearchMultiRequest {
  multiSearchId: string;
  requests: NewSearchRequest[];
  skipSearch: boolean;
}

export interface EditSearchMultiRequest {
  multiSearchId: string;
  requests: EditSearchRequestByIndex[];
  skipSearch: boolean;
  skipUpdateTsDeltasLastViewed: boolean;
}

export interface GetSearchMultiRequest {
  multiSearchId: string;
  requests: GetSearchRequestByIndex[];
  skipSearch: boolean;
}

export interface RemoveSearchMultiRequest {
  multiSearchId: string;
  /** Indices to remove from the search uuids array */
  requests: number[];
}

export interface EditSearchMultiFilterGroupRequest {
  multiSearchId: string;
  requests: EditSearchFilterGroupRequestByIndex[];
}

export interface RemoveSearchMultiFilterGroupRequest {
  multiSearchId: string;
  requests: RemoveSearchFilterGroupRequestByIndex[];
}

export interface Token {
  filter?: FilterToken | undefined;
  rangeFilter?: RangeFilterToken | undefined;
  op?: OpToken | undefined;
}

export interface FilterToken {
  key: number;
  filterObjects: FilterObject[];
  operator: BooleanOp;
}

export interface FilterObject {
  value: string;
  /** optional */
  label: string;
}

export interface RangeFilterToken {
  /** Default to inclusive */
  key: number;
  minValue: RangeValuePointer | undefined;
  maxValue: RangeValuePointer | undefined;
}

export interface RangeValuePointer {
  /** Allow us to check if 0 was sent intentionally */
  value: string;
  inclusive: boolean;
  label: string;
}

export interface OpToken {
  operation: BooleanOp;
}

export interface SearchResponse {
  searchId: string;
  companyTab: Tab | undefined;
  investorTab: Tab | undefined;
  companyDealTab: Tab | undefined;
  newsTab: News | undefined;
  researchTab: Research | undefined;
  universeQuery: PanelCategory | undefined;
  earningsTab: EarningsTranscript | undefined;
  patentsTab: Patents | undefined;
}

/** This represents the universe category on the side panel, or all the tab specific categories on the side panel */
export interface PanelCategory {
  operator: BooleanOp;
  /** Each PanelColumn represents one "column" in a category */
  panelColumns: PanelColumn[];
  idPanelCategory: number;
  panelCategoryName: string;
  isHidden: boolean;
}

/**
 * Most PanelColumns will have one operand (ex. Company Status).
 * Some will have multiple operands (ex. Industry column has industry, sector, and sub-industry)
 */
export interface PanelColumn {
  groupKey: number;
  groupName: string;
  operands: OperandInfo[];
  breadcrumbs: SimpleBreadcrumb[];
  selectMode: SelectMode;
  operator: BooleanOp;
}

export interface MultiSearchResponse {
  multiSearchId: string;
  queryExpressions: QueryExpressionInfo[];
  responseMap: { [key: string]: SearchResponse };
  isLegacyOrSearch: boolean;
}

export interface MultiSearchResponse_ResponseMapEntry {
  key: string;
  value: SearchResponse | undefined;
}

export interface Tab {
  searchColumns: SearchColumn[];
  sectionData: SectionData | undefined;
  paginationInfo: PaginationInfo | undefined;
  simpleBreadcrumbs: SimpleBreadcrumb[];
  /**
   * Deprecated
   *
   * @deprecated
   */
  tabQuery: PanelCategory | undefined;
  panelBreadcrumbs: SimpleBreadcrumb[];
  panelCategories: PanelCategory[];
  idSavedSearch: number;
  charts: EditedChart[];
  /**
   * Using CellValueInfo type for kpis because they're the most extensible implementation that FE already
   * knows about, and can work for our current needs.
   */
  kpis: CellValueInfo | undefined;
  isSemanticSearch: boolean;
}

export interface SimpleBreadcrumb {
  type: BreadcrumbType;
  idColumnType: number;
  name: string;
  value: string;
  operator: BooleanOp;
}

export interface PaginationInfo {
  limit: number;
  offset: number;
  hitCount: number;
  totalHitCount: number;
  nextLastValueSorts: SortRequest[];
}

export interface SectionData {
  idSection: number;
  sectionRows: SectionRow[];
}

export interface SectionRow {
  idRow: number;
  sectionCells: SectionCell[];
  semanticMatches: SemanticSearchMatches[];
}

export interface SemanticSearchMatches {
  searchFieldName: number;
  matchedTokens: string[];
}

export interface SectionCell {
  idCell: number;
  idColumn: number;
  idRow: number;
  cellValues: CellValueInfo[];
  cellMetadata: { [key: number]: CellMetadataWrapper };
}

export interface SectionCell_CellMetadataEntry {
  key: number;
  value: CellMetadataWrapper | undefined;
}

export interface CellMetadataWrapper {
  cellValues: CellValueInfo[];
}

export interface CellValueInfo {
  /** Note: the order of displayValues doesn't matter b/c they all will have a unique displayValueClass value */
  displayValues: DisplayValue[];
  /** Which order this value appears in its cell */
  valueOrder: number;
}

export interface DisplayValue {
  /**
   * What type of data the displayValueId / dataId values represent (i.e. company, investor, logo, etc.)
   * NOTE: these representations are stored in constants on the BE / FE
   */
  displayValueClass: number;
  /** The ID directly associated with this value */
  displayValueId: number;
  /** The text (if any) that gets displayed on the FE for this value */
  displayValueText: string;
  /**
   * The ID indirectly associated with this value
   * ex. If this value is a Mosaic score, the dataId would be the company's ID,
   *     since the mosic score can't be found directly with the company ID
   *     but it's still associated with the company
   */
  dataId: number;
}

export interface Expression {
  expressions: Expression[];
  operand: FilterToken | undefined;
  operandRange: RangeFilterToken | undefined;
  operator: OpToken | undefined;
}

export interface QueryExpressionInfo {
  searchId: string;
  expression: Expression | undefined;
}

export interface ColumnCategoryInfo {
  columns: ColumnTypeInfo[];
  name: string;
}

export interface ColumnTypeInfo {
  idColumnType: number;
  name: string;
  dataType: IdAndName | undefined;
  hasAccess: boolean;
  isSelected: boolean;
  secondaryName: string;
}

export interface ColumnType {
  idColumnType: number;
  name: string;
  dataType: IdAndName | undefined;
  hasAccess: boolean;
  secondaryName: string;
}

export interface IdAndName {
  id: number;
  name: string;
}

export interface SearchColumn {
  idColumn: number;
  columnType: ColumnType | undefined;
  name: string;
  width: string;
  sortDirection: SortDirection;
  sortPriority: number;
  selectMode: SelectMode;
  operands: OperandInfo[];
  isSortable: boolean;
  secondaryName: string;
  isFilterable: boolean;
}

export interface ColumnRequest {
  idColumnType: number;
  width: string;
}

export interface SortRequest {
  idColumnType: number;
  sortDirection: SortDirection;
  lastValue: string;
}

export interface GetColumnCategoryInfosRequest {
  searchId: string;
  tab: SearchTab;
}

export interface GetColumnCategoryInfosResponse {
  columnCategories: ColumnCategoryInfo[];
}

export interface RemoveAllFiltersAndSortsForTabRequest {
  searchId: string;
  tabs: TabsToClear | undefined;
}

export interface RemoveAllFiltersAndSortsForTabMultiRequest {
  multiSearchId: string;
  /** Remove the filters from the searches at these indexes, for the specified tabs */
  requests: RemoveAllFiltersAndSortsForTabByIndex[];
}

export interface RemoveAllFiltersAndSortsForTabByIndex {
  index: number;
  tabs: TabsToClear | undefined;
  includeTableKPIs: boolean;
}

export interface NewSearchFromLegacySearchRequest {
  /** legacy search api call url params */
  apiCallArgs: { [key: string]: string };
  /** type of the search - company/investor/deal */
  searchTab: SearchTab;
  /** optional. specify if you want to save the search */
  savedSearchName: string;
  /** optional. */
  legacySearchId: number;
  emailFrequency: EmailFrequency;
  skipSearch: boolean;
}

export interface NewSearchFromLegacySearchRequest_ApiCallArgsEntry {
  key: string;
  value: string;
}

export interface NewSearchFromLegacySearchResponse {
  multiSearchResponse: MultiSearchResponse | undefined;
  savedSearchInfo: SavedSearchInfo | undefined;
  skippedKeys: string[];
}

/**
 * ClearPanelColumnsByIndex is a message type used for clearing PanelColumn types,
 * which are used in Open Search, in the left-hand panel of the ISS UI.
 */
export interface ClearPanelColumnsByIndex {
  multiSearchId: string;
  index: number;
  /** Optional */
  idPanelCategory: number;
  /** Optional */
  idColumnType: number;
  /** The current tab to stay on */
  currentTab: SearchTab;
  includeTableKPIs: boolean;
}

export interface TabsToClear {
  /** If true, remove the filters for the tab */
  company: boolean;
  investor: boolean;
  companyDeal: boolean;
  news: boolean;
  research: boolean;
  earnings: boolean;
}

export interface GetFilterAutocompleteResultsRequest {
  searchId: string;
  idColumnType: number;
  searchSubstring: string;
  tab: SearchTab;
  limit: number;
  offset: number;
}

export interface GetFilterAutocompleteResultsResponse {
  /** One CellValueInfo corresponds to one value in the filter dropdown */
  cellValues: CellValueInfo[];
  totalHits: number;
  limit: number;
  offset: number;
  hasMore: boolean;
  canPaginate: boolean;
  cellValueNodes: CellValueNode[];
  structureType: StructureType;
}

export interface CellValueNode {
  children: CellValueNode[];
  cellValueInfo: CellValueInfo | undefined;
}

export interface News {
  newsArticles: NewsArticle[];
  paginationInfo: PaginationInfo | undefined;
  chartData: ChartData[];
  topPlayers: TopPlayer[];
  filters: FilterSummary[];
  idSavedSearch: number;
  isSemanticSearch: boolean;
}

export interface NewsArticle {
  title: string;
  content: string;
  date: string;
  idNews: number;
  newsUrl: string;
  score: number;
  mentionedOrgs: Org[];
  newsImageUrl: string;
}

export interface Research {
  researches: ResearchBrief[];
  paginationInfo: PaginationInfo | undefined;
  sorts: SortRequest[];
  idSavedSearch: number;
  panelCategories: PanelCategory[];
  isSemanticSearch: boolean;
}

export interface ResearchBrief {
  title: string;
  type: string;
  typeID: number;
  contentTierID: number;
  eventDetailsCity: string;
  eventDetailsStart: string;
  isExpert: boolean;
  link: string;
  postDate: string;
  postID: number;
  featuredImageUrl: string;
  primaryImageUrl: string;
  secondaryImageUrl: string;
  tags: ResearchBriefTag[];
  contentTierNewID: number;
  subTitle: string;
  taxonomy:
    | ResearchBrief_Taxonomy
    | undefined;
  /** DEPRECATED: Use yardstiqTranscript.subjectOrgId */
  transcriptSubjectOrgId: number;
  yardstiqTranscript: ResearchBrief_YardstiqTranscript | undefined;
  featuredFieldAttributes: ResearchBrief_FieldAttribute[];
  attachments: ResearchBrief_Attachment[];
  contentHighlights: string[];
}

export interface ResearchBrief_Taxonomy {
  industry: string[];
  subIndustry: string[];
}

export interface ResearchBrief_YardstiqTranscript {
  csatScore: number;
  renewalIntention: number;
  pullQuote: string;
  buyerSpeakerTitle: string;
  buyerOrgName: string;
  mentionedOrgLogoUrl: string;
  subjectOrgId: number;
  subjectOrgName: string;
  subjectOrgDescription: string;
}

export interface ResearchBrief_FieldAttribute {
  fieldName: string;
  locked: boolean;
}

export interface ResearchBrief_Attachment {
  url: string;
  pages: ResearchBrief_Page[];
}

export interface ResearchBrief_Page {
  number: number;
  content: string;
}

export interface ResearchBriefTag {
  title: string;
  urlPath: string;
}

export interface CreateSavedSearchRequest {
  savedSearchName: string;
  multiSearchId: string;
  currentTab: SearchTab;
  notificationsEnabled: boolean;
  /** optional. specify if saving this search from a legacy saved search */
  legacySearchId: number;
  /** Will be replaced with DeliverySchedule once other repos are updated to use it */
  emailFrequency: EmailFrequency;
  deliverySchedule: DeliverySchedule | undefined;
}

export interface EditSavedSearchRequest {
  /** Allows updating the associated multiSearchId */
  idSavedSearch: number;
  multiSearchId: string;
  savedSearchName: string;
}

export interface DeleteSavedSearchRequest {
  idSavedSearch: number;
}

export interface ListSavedSearchesRequest {
  idSavedSearches: number[];
  limit: number;
  offset: number;
  sortInfo: SortInfo | undefined;
}

export interface SortInfo {
  sortType: SortType;
  sortDirection: SortDirection;
}

export interface SavedSearchInfo {
  idSavedSearch: number;
  savedSearchName: string;
  multiSearchId: string;
  currentTab: SearchTab;
  idUser: number;
  idTeam: number;
  tsLastRun: Date | undefined;
  tsDeltasLastViewed: Date | undefined;
  tsCreated: Date | undefined;
  isDeleted: boolean;
  newResultCount: number;
  notificationsEnabled: boolean;
  tsLastViewed: Date | undefined;
  tsLastEmailSent:
    | Date
    | undefined;
  /** Will be replaced with DeliverySchedule once other repos are updated to use it */
  emailFrequency: EmailFrequency;
  tsLastEdited: Date | undefined;
  deliverySchedule: DeliverySchedule | undefined;
}

export interface DeliverySchedule {
  frequency: EmailFrequency;
  dayOfWeek: DayOfWeek;
  timeOfDay: TimeOfDay | undefined;
  timeZone: TimeZone | undefined;
}

export interface ListSavedSearchesResponse {
  savedSearches: SavedSearchInfo[];
}

export interface UpdateSavedSearchRequest {
  savedSearch: SavedSearchInfo | undefined;
  updateMask: string[] | undefined;
}

export interface BatchUpdateSavedSearchesRequest {
  parent: string;
  requests: UpdateSavedSearchRequest[];
}

export interface BatchUpdateSavedSearchesResponse {
  savedSearches: SavedSearchInfo[];
}

export interface EarningsTranscript {
  results: EarningsTranscript_Details[];
  topPlayers: TopPlayer[];
  chartData: ChartData[];
  paginationInfo: PaginationInfo | undefined;
  sorts: SortRequest[];
  /** Deprecated */
  breadcrumbGroups: EarningsTranscript_BreadcrumbGroup[];
  filters: FilterSummary[];
  idSavedSearch: number;
  panelCategories: PanelCategory[];
  isSemanticSearch: boolean;
}

/**
 * EarningsTranscript.BreadcrumbGroup includes a negate field which represents
 * when a group is excluding values of that type
 * example: "Uber SOURCE: ALL NOT Twilio" would have a Breadcrumbgroup with
 * negate set to True.
 */
export interface EarningsTranscript_BreadcrumbGroup {
  key: number;
  negate: boolean;
  breadcrumbs: EarningsTranscript_BreadcrumbGroup_GroupItem[];
}

export interface EarningsTranscript_BreadcrumbGroup_GroupItem {
  name: string;
  value: string;
}

export interface EarningsTranscript_Details {
  transcriptTitle: string;
  transcriptPubDate: string;
  entityName: string;
  entityType: string;
  idEntity: number;
  sentences: EarningsTranscript_Details_Sentence[];
  transcriptAuthor: string;
}

export interface EarningsTranscript_Details_Sentence {
  idSubBlock: number;
  sentence: string;
  /** position is the index of the sentence within its sub block */
  position: number;
  /** this needs to be a string because we append transcript type to avoid overlapping ids between quartr and seeking alpha */
  idTranscript: string;
}

export interface Org {
  idOrg: number;
  idInvestor: number;
  idCompany: number;
  name: string;
  logoUrl: string;
  orgUrl: string;
}

export interface FilterSummary {
  key: number;
  negate: boolean;
  items: FilterSummary_Item[];
}

export interface FilterSummary_Item {
  name: string;
  value: string;
}

export interface ChartData {
  source: string;
  time: string;
  mentions: number;
}

export interface TopPlayer {
  mentions: number;
  org: Org | undefined;
}

export interface DownloadCSVRequestMetadata {
  referrer: string;
  url: string;
  device: string;
  browser: string;
}

export interface DownloadCSVRequest {
  searchId: string;
  tab: SearchTab;
  selectMode: SelectMode;
  /**
   * If selectMode is NONE, these ids are inclusive.
   * If selectMode is ALL, they are exclusive.
   */
  idEntities: number[];
  view: View;
  usedCredits: number;
  metadata: DownloadCSVRequestMetadata | undefined;
}

export interface DownloadCSVResponse {
  downloadUrl: string;
  idActionStatus: number;
}

export interface ApplyTagsRequest {
  searchId: string;
  tab: SearchTab;
  selectMode: SelectMode;
  /**
   * If selectMode is NONE, these ids are inclusive.
   * If selectMode is ALL, they are exclusive.
   */
  idEntities: number[];
  tagsToAdd: string[];
  tagsToDelete: string[];
  idTagType: number;
  view: View;
}

export interface ApplyTagsResponse {
  idActionStatus: number;
}

export interface GetTagsRequest {
  searchId: string;
  tab: SearchTab;
  selectMode: SelectMode;
  /**
   * If selectMode is NONE, these ids are inclusive.
   * If selectMode is ALL, they are exclusive.
   */
  idEntities: number[];
  idTagType: number;
  limit: number;
  offset: number;
  searchTerm: string;
}

export interface GetTagsResponse {
  commonTags: string[];
  allTags: string[];
  totalCount: number;
}

export interface AddEntitiesToCxnRequest {
  searchId: string;
  tab: SearchTab;
  selectMode: SelectMode;
  /**
   * If selectMode is NONE, these ids are inclusive.
   * If selectMode is ALL, they are exclusive.
   */
  idEntities: number[];
  idCollection: number;
  idSheet: number;
  idSection: number;
  duplicatesCheck: DuplicatesCheck;
  collectionName: string;
  sheetName: string;
  topSearchQuery: string;
  url: string;
  device: string;
}

export interface AddEntitiesToCxnResponse {
  idActionStatus: number;
  duplicates: DuplicatesInfo | undefined;
  warning: AddEntitiesToCxnWarning;
}

export interface DuplicatesInfo {
  totalDuplicates: number;
  sampleNameList: string[];
}

export interface GetEarningsTranscriptExcerptRequest {
  idSubBlock: number;
  sentencePosition: number;
  highlightTerms: string[];
  /** this needs to be a string because we append transcript type to avoid overlapping ids between quartr and seeking alpha */
  idTranscript: string;
}

export interface EarningsTranscriptExcerpt {
  speakerName: string;
  speakerTitle: string;
  speakerFirm: string;
  sourceLink: string;
  text: string;
  paragraphs: string[];
}

export interface Patents {
  patents: PatentDoc[];
  paginationInfo: PaginationInfo | undefined;
  topTopics: PatentTopicCount[];
  filters: FilterSummary[];
  idSavedSearch: number;
  panelBreadcrumbs: SimpleBreadcrumb[];
  panelCategories: PanelCategory[];
  isSemanticSearch: boolean;
}

export interface PatentDoc {
  title: string;
  abstract: string;
  url: string;
  grantUrl: string;
  isGranted: boolean;
  fileDate: string;
  pubDate: string;
  grantDate: string;
  topics: string[];
  orgs: Org[];
}

export interface ChartRequest {
  multiSearchId: string;
  type: ChartType;
  /** See documentation on each chart type for info on which fields are supported */
  index: number;
  filter: Expression | undefined;
  interval: ChartRequest_ChartInterval;
  startDate: string;
  endDate: string;
  idOrgs: number[];
  includeNonEquityFunding: boolean;
}

export enum ChartRequest_ChartInterval {
  CHART_INTERVAL_UNSPECIFIED = "CHART_INTERVAL_UNSPECIFIED",
  QUARTERLY = "QUARTERLY",
  YEARLY = "YEARLY",
  MONTHLY = "MONTHLY",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export interface ChartResponse {
  multiSearchId: string;
  type: ChartType;
  chart: ChartResponse_Chart[];
  request: ChartRequest | undefined;
  chartEdit: EditedChart | undefined;
}

export interface ChartResponse_Chart {
  patentTrends?: PatentTrendsChart | undefined;
  patentTopPlayers?: PatentTopPlayersChart | undefined;
  patentTopTopics?: PatentTopTopicsChart | undefined;
  barChart?: BarChart | undefined;
  treemapChart?: TreemapChart | undefined;
}

export interface PatentTrendsChart {
  intervals: PatentTrendsChart_Bucket[];
}

export interface PatentTrendsChart_Bucket {
  date: string;
  patentCount: number;
  playerCount: number;
}

export interface PatentTopPlayersChart {
  players: PatentTopPlayersChart_Player[];
}

export interface PatentTopPlayersChart_Player {
  id: number;
  name: string;
  patentCount: number;
}

export interface PatentTopTopicsChart {
  topics: PatentTopicCount[];
}

export interface PatentTopicCount {
  topic: string;
  patentCount: number;
}

export interface BarChart {
  intervals: BarChart_BarChartBucket[];
}

export interface BarChart_BarChartBucket {
  date?: string | undefined;
  org?: Org | undefined;
  otherLabel?: string | undefined;
  barValueFloat?: number | undefined;
  barValueInt?: number | undefined;
  barValueLabelMin: number;
  barValueLabelMax: number;
  lineValue: number;
  orgIdStatus?: number | undefined;
}

export interface TreemapChart {
  data: TreemapChart_TreemapNode | undefined;
}

export enum TreemapChart_NodeType {
  UNKNOWN_NODE = "UNKNOWN_NODE",
  SECTOR_NODE = "SECTOR_NODE",
  INDUSTRY_NODE = "INDUSTRY_NODE",
  SUBINDUSTRY_NODE = "SUBINDUSTRY_NODE",
  CONTINENT_NODE = "CONTINENT_NODE",
  COUNTRY_NODE = "COUNTRY_NODE",
  STATE_NODE = "STATE_NODE",
  CITY_NODE = "CITY_NODE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export interface TreemapChart_TreemapNode {
  name: string;
  value: number;
  secondaryValue: number;
  tertiaryValue: number;
  nodeType: TreemapChart_NodeType;
  /** Maps to the db table for the given node type */
  nodeId: number;
  children: TreemapChart_TreemapNode[];
}

export interface GetChartDataMultiRequest {
  multiSearchId: string;
  chartRequests: ChartRequest[];
}

export interface GetChartDataMultiResponse {
  charts: ChartResponse[];
}

export interface TransformCriteriaRequest {
}

export interface TransformCriteriaResponse {
  numCriteriaTransformed: number;
}

export interface DataFeed {
  /** idDataFeed */
  name: string;
  idSavedSearch: number;
  frequency: DataFeedFrequency;
  tsLastRun: Date | undefined;
  lastS3Filename: string;
}

export interface CreateDataFeedRequest {
  parent: string;
  dataFeed: DataFeed | undefined;
}

export interface BatchCreateDataFeedsRequest {
  parent: string;
  requests: CreateDataFeedRequest[];
}

export interface BatchCreateDataFeedsResponse {
  dataFeeds: DataFeed[];
}

export interface TestBatchDeleteDataFeedsRequest {
  parent: string;
  /** idDataFeeds */
  names: string[];
}

export interface UpdateDataFeedRequest {
  dataFeed: DataFeed | undefined;
  updateMask: string[] | undefined;
}

export interface BatchUpdateDataFeedsRequest {
  parent: string;
  requests: UpdateDataFeedRequest[];
}

export interface BatchUpdateDataFeedsResponse {
  dataFeeds: DataFeed[];
}

export interface ListDataFeedsRequest {
  parent: string;
  pageSize: number;
  pageToken: string;
  filter: Filter[];
  orderBy: OrderBy[];
}

export interface ListDataFeedsResponse {
  dataFeeds: DataFeed[];
  nextPageToken: string;
}

export interface CreateSearchFromInvestorSearchRequest {
  multi_search_id: string;
  expression: Expression | undefined;
  search_type: CreateSearchFromInvestorSearchRequest_SearchType;
}

export enum CreateSearchFromInvestorSearchRequest_SearchType {
  UNSPECIFIED = "UNSPECIFIED",
  NUMBER_OF_DEALS = "NUMBER_OF_DEALS",
  NUMBER_OF_COMPANIES = "NUMBER_OF_COMPANIES",
  TOTAL_NUMBER_OF_DEALS = "TOTAL_NUMBER_OF_DEALS",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export interface CreateSearchFromInvestorSearchResponse {
  multi_search_id: string;
}

export interface OutOfTheBoxFeed {
  id: number;
  idSavedSearch: number;
  lastS3Filename: string;
  tsLastRun: Date | undefined;
  frequency: DataFeedFrequency;
  teams: OutOfTheBoxFeedTeam[];
}

export interface OutOfTheBoxFeedTeam {
  id: number;
  hasRunBefore: boolean;
  sendFullFeed: boolean;
}

/**
 * This resource has no parents
 * field 1 is kept empty intentionally for all request messages
 * for parent field
 */
export interface ListOutOfTheBoxFeedsRequest {
  pageSize: number;
  pageToken: string;
  filter: Filter[];
  orderBy: OrderBy[];
}

export interface ListOutOfTheBoxFeedsResponse {
  outOfTheBoxFeeds: OutOfTheBoxFeed[];
  nextPageToken: string;
}

export interface UpdateOutOfTheBoxFeedRequest {
  outOfTheBoxFeed: OutOfTheBoxFeed | undefined;
  updateMask: string[] | undefined;
}

export interface BatchUpdateOutOfTheBoxFeedsRequest {
  requests: UpdateOutOfTheBoxFeedRequest[];
}

export interface BatchUpdateOutOfTheBoxFeedsResponse {
  outOfTheBoxFeeds: OutOfTheBoxFeed[];
}

export interface CreateOutOfTheBoxFeedRequest {
  outOfTheBoxFeed: OutOfTheBoxFeed | undefined;
}

export interface TestBatchCreateOutOfTheBoxFeedsRequest {
  requests: CreateOutOfTheBoxFeedRequest[];
}

export interface TestBatchCreateOutOfTheBoxFeedsResponse {
  outOfTheBoxFeeds: OutOfTheBoxFeed[];
}

export interface TestBatchDeleteOutOfTheBoxFeedsRequest {
  /** idOutOfTheBoxFeed */
  ids: number[];
}

export interface PanelColumnBucketsRequest {
  idMultiSearch: string;
  tab: SearchTab;
  panelColGroupKey: number[];
  searchString: string;
}

export interface PanelColumnBucketsResponse {
  hits: PanelColumnBucket[];
}

export interface PanelColumnBucket {
  panelColGroupKey: number;
  buckets: PanelColumnBucket_Bucket[];
}

export interface PanelColumnBucket_Bucket {
  id: number;
  filterLabel: string;
  count: number;
  selected: boolean;
}
